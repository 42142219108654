
<template>
    <div>
        <h3>{{ author.about ? 'O autorovi výletu' : 'Autor výletu' }}</h3>
        <div class="about-author-container" :class="{'align-items-center': !author.about}">
            <img v-if="author.avatarFileName" class="user-picture" :src="userPictureFilePath" alt="">
            <div v-else class="user-picture">{{ initials }}</div>
            <div class="author-info">
                <p class="username">{{ author.userName }}</p>
                <p class="about">
                    <ExpandableTextComponent :amountOfVisibleLines="3" :windowResized="windowResized">
                        {{author.about}}
                    </ExpandableTextComponent>
                </p>
                
            </div>
        </div>
    </div>

</template>

<script>
    import {CLEVERTRIP_API_URL}  from '@/definitions';
    import sharedUtils from '@/utils/sharedUtils'
    import ExpandableTextComponent from './ExpandableTextComponent.vue';

    export default {
        props: {
            author: {
                required: true,
                type: Object
            },
            windowResized: {
                type: Boolean
            }
        },
        components: {
            ExpandableTextComponent,
        },
        data() {
            return {
                imgURL: CLEVERTRIP_API_URL + "/avatar/downloadFile/",

            }
        },

        computed: {
            initials() {
                return sharedUtils.extractInitials(this.author.userName)
            },
            userPictureFilePath() {
                if (!this.author.avatarFileName) return
                return sharedUtils.getPictureUrlLowResolution(this.author.avatarFileName, this.imgURL)
            }
        }
    }

</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    h3 {
        margin-bottom: 30px;
        font-size: 25px;
    }

    .about-author-container {
        display: flex;
        background-color: $tr-white;
        filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 10px);
        padding-block: 30px;
        padding-inline: 50px;
        margin-bottom: 50px;
        width: fit-content;

        &.align-items-center p {
            margin: 0;
        }

        @media (max-width: 576px) {
            padding-inline: 20px;
        }

        .user-picture {
            width: 50px;
            height: 50px;
            background-color: $tr-green;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;
            margin-right: 20px;

            @media (max-width: 568px) {
                width: 40px;
                height: 40px;
            }
        }


        p {
            max-width: 660px;
        }

        .username {
            margin-bottom: 10px;
            font-size: 20px;
        }
    }

</style>