
import { apolloClient } from "@/api/apolloClient";
import {CREATE_TRAVEL_TIP_ASSESSMENT, UPDATE_TRAVEL_TIP_ASSESSMENT, DELETE_TRAVEL_TIP_ASSESSMENT} from "../api/graphql/mutation/TravelTipAssessmentMutation";

export default {
    createTravelTipAssessment: function(form) {
        console.log("Start to creating assessment by user: " + form.user);
        const { comment, travelTip, categories, overallRating } = form;

        const variables = {
            comment,
            travelTip,
            overallRating
        }

        categories.forEach(category => {
            if (category.value != 0) {
                variables[category.attribute] = category.value
            }
        })
        
        return apolloClient.mutate({
            mutation: CREATE_TRAVEL_TIP_ASSESSMENT,
            variables: variables
        }).then((result) => {
            console.log("Assessment created: ", result);
            return result;
        });
    },

    updateTravelTipAssessment: function(id, form) {
        console.log("Start to updating assessment with ID " + form.id);
        const { comment, travelTip, categories, overallRating } = form;

        const variables = {
            id,
            comment,
            travelTip,
            overallRating
        }

        categories.forEach(category => {
            if (category.value != 0) {
                variables[category.attribute] = category.value
            }
        })
        return apolloClient.mutate({
            mutation: UPDATE_TRAVEL_TIP_ASSESSMENT,
            variables: variables
        }).then((result) => {
            console.log("Assessment updated: ", result);
            return result;
        });
    },

    deleteTravelTipAssessment: function(id) {
        console.log("Start to deleting assessment with ID " + id);
        return apolloClient.mutate({
            mutation: DELETE_TRAVEL_TIP_ASSESSMENT,
            variables: {
                id,
            },
        }).then((result) => {
            console.log("Assessment deleted: ", result);
            return result;
        });
    }
}