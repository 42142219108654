<template>
    <div class="assessment" :class="{'border-bottom': index !== assessmentsLength - 1}">
        <img v-if="isUserAllowedToEditAssessment" class="icon-delete" src="@/assets/svg/icon-delete.svg" @click="$bvModal.show('assessment-delete-modal' + assessment.id)"/>
        <img v-if="isUserAllowedToEditAssessment" class="icon-edit" src="@/assets/svg/icon-edit.svg" @click="$emit('showEditAssessmentModal', assessment)"/>
        <div class="d-flex align-items-center">
            <img v-if="assessment.author.avatarFileName" class="picture" :src="userPictureFilePath(assessment.author.avatarFileName)" alt="">
            <div v-else class="picture">{{ initials }}</div>
            <p class="user-name">{{assessment.author.userName}}</p>
            <div class="created-at">{{ formatDate(assessment.createdAt) }}</div>
        </div>
        <StarRating name="rating" v-model="assessment.overallRating" :disabled="true"/>
        <p class="comment">
            <ExpandableTextComponent :windowResized="windowResized">
                {{ assessment.comment }}
            </ExpandableTextComponent>
        </p>
        <b-modal class="modal" :id="`assessment-delete-modal${assessment.id}`" hide-footer hide-header no-close-on-backdrop>
            <div class="delete-modal-container">
                <h3 v-if="assessment.author.id === $store.state.currentUserId" class="moda-title pb-3">{{ $t("modals.assessmentDeleteTitle") }}</h3>
                <h3 v-else class="moda-title pb-3">{{ $t("modals.assessmentDeleteOthersTitle", [assessment.author.userName]) }}</h3>
                <div class="buttons-container">
                    <TrekioButton secondary @click="$bvModal.hide('assessment-delete-modal' + assessment.id)">{{$t("general.back")}}</TrekioButton>
                    <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.delete")}}</TrekioButton>
                </div>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import {CLEVERTRIP_API_URL}  from '@/definitions';
import sharedUtils from '@/utils/sharedUtils'
import StarRating from '@/components/StarRating'
import TravelTipAssessmentService from '@/services/TravelTipAssessmentService.js'
import ExpandableTextComponent from './ExpandableTextComponent.vue';

    export default {
        name: 'TravelTipAssessment',
        props: ["assessment", 'index', 'assessmentsLength', 'windowResized'],

        components: {
            StarRating,
            ExpandableTextComponent
        },

        data() {
            return {
                imgURL: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
                loadingDelete: ''
            }
        },

        computed: {
            initials() {
                return sharedUtils.extractInitials(this.assessment.author.userName)
            },
            isUserAllowedToEditAssessment() {
                return ['ADMIN', 'ADMIN_SUPERVISOR'].includes(this.$store.state.currentUserRole)
                        || this.$store.state.currentUserId == this.assessment.author.id
            }
        },

        methods: {
            async onDeleteConfirmed() {
                this.loadingDelete = this.$t("loading.assessmentDelete")
                await TravelTipAssessmentService.deleteTravelTipAssessment(this.assessment.id)
                    .then(resp => {
                        this.$emit('refetchAssessments')
                    })
                
                this.loadingDelete = ''
            },
            formatDate(date) {
                const moment = require('moment');
                return moment(date).format("DD. MM. YYYY");
            },
            userPictureFilePath(fileName) {
                return sharedUtils.getPictureUrlLowResolution(fileName, this.imgURL)
            },
        },

    };


</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .delete-modal-container {
        padding-inline: 30px;
        padding-block: 30px;

        .buttons-container {
            display: flex;
            flex-wrap: wrap;
            gap: 40px
        }

        @media (max-width: 576px) {
            padding-inline: 0;
        }
    }

    .assessment {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 30px;
        position: relative;

        .icon-delete, .icon-edit {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 80px;
            right: 0;
            cursor: pointer;
        }

        .icon-edit {
            top: 120px;
        }

        &.border-bottom {
            border-bottom: 1px solid $tr-light-gray;
        }

        .user {
            display: flex;
            align-items: center;
            gap: 16px;
        }
        .picture {
            width: 30px;
            height: 30px;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $tr-green;
            border-radius: 50%;
        }

        .user-name {
            margin-inline: 20px;
            margin-bottom: 0;
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .created-at {
            margin-left: auto;
            color: $tr-gray;
            font-size: 14px;
            flex-shrink: 0;
        }

        .comment {
            margin-bottom: 30px;
            white-space: pre-line;
            padding-right: 35px;
        }
    }
</style>
  