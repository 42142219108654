<template>
    <div class="travel-tip-detail-page-modal">
        <div class=x-background>
            <button class="x dismiss-x" @click="hideMapModal"></button>
        </div>
        <section class="travel-tip-detail-page-modal-body">
            <div class="day-pick-buttons" v-if="showDayButtons && allRoutes.length > 1">
            <button class="map-day-pick-button" :class="{active: activeDay == -1}" @click="showDay(-1)">{{ $t("map.allDays") }}</button>
            <button class="map-day-pick-button" :disabled="layer.disabled ? true : false" :class="{active: activeDay == index, disabled: layer.disabled}" v-for="(layer, index) in allRoutes" :key="layer._id" @click="showDay(index)"
                                                @mouseover="highlightRoute(index)" @mouseout="unhighlightRoute(index)"
            >{{ $t("general.dayOrder", [index + 1]) }}</button>
            <div class="map-day-pick-button" v-tooltip.bottom="$t('info.routeMayDiffer')" v-if="activeDay > -1 && !allRoutes[activeDay].disabled" style="padding-inline: 7px"><a target="_blank" :href="allDaysMapyczUrls[activeDay]">{{ $t("map.viewTheDayOnMapyCZ") }}</a></div>
            </div>
            <div class="day-pick-buttons" v-else-if="showDayButtons">
            <div class="map-day-pick-button" v-tooltip.bottom="$t('info.routeMayDiffer')" style="padding-inline: 7px"><a target="_blank" :href="allDaysMapyczUrls[0]">{{ $t("map.viewTheDayOnMapyCZ") }}</a></div>
            </div>
            <div class="map-loading" v-if="noItemsError">{{ $t("loadingErrors.mapNoItems") }}</div>
            <div class="map-loading" v-else-if="itemWithoutLocationError">{{ $t("loadingErrors.mapMissingItems") }}</div>
            <div class="map-loading" v-else-if="unexpectedError">{{ $t("loadingErrors.mapUnknown") }}</div>
            <div class="map-loading" v-else-if="isMapLoading">{{ $t("loading.map") }}</div>
            <div class="map-loading" v-else-if="isRouteLoading">{{ $t("loading.calculatingRoute") }}</div>
            <div id="map" class="public-map travel-tip-detail-page-modal-body-map"></div>
            <div class="marker-item-info" :class="{show: showMarkerInfo}">
                <div class="item-info-container" :key="itemInfoReloadKey">
                    <button class="x dismiss-x" @click="hideMarkerInfo"></button>
                    <div class="item-title-with-description">
                    <p class="item-title">{{ markerItemInfo.title }}</p>
                    <p class="item-description">{{ markerItemInfo.description }}</p>
                    </div>
                    <div class="image-container" v-if="markerItemInfo.itineraryItemPictures.length == 1">
                        <figure v-lazyload v-if="markerItemInfo.itineraryItemPictures.length == 1">
                            <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                            <img class="img-fluid" :data-url="imgUrl + markerItemInfo.itineraryItemPictures[0].fileName.slice(0,markerItemInfo.itineraryItemPictures[0].fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + markerItemInfo.itineraryItemPictures[0].fileName.slice(markerItemInfo.itineraryItemPictures[0].fileName.lastIndexOf('.'))">
                        </figure>
                    </div>
                    <div class="carousel-container" v-else-if="markerItemInfo.itineraryItemPictures.length > 1">
                    <hooper ref="carousel" :settings="hooperSettings" :wheel-control="false" :transition="500" :autoPlay="false" :playSpeed="8000" :centerMode="false" pagination="no" :infiniteScroll="false" :perPage=2 :speed=300 :loop="true" :minSwipeDistance=10 :scrollPerPage="false" :paginationEnabled="false">
                        <slide class="image-slide" v-for="picture in markerItemInfo.itineraryItemPictures" :key="picture.id">
                        <figure v-lazyload class="position-relative ratio-four-by-three">
                            <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                            <img class="img-fluid" :data-url="imgUrl + picture.fileName.slice(0,picture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + picture.fileName.slice(picture.fileName.lastIndexOf('.'))">
                        </figure>
                        </slide>
                        <hooper-navigation v-if="markerItemInfo.itineraryItemPictures.length > 1" slot="hooper-addons"></hooper-navigation>
                    </hooper>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {CLEVERTRIP_IMG_URL} from '@/definitions';
import {Hooper, Slide, Navigation as HooperNavigation} from "hooper";
import LazyLoadDirective from "@/components/LazyLoadDirective.js";

    export default {
        name: "Map",
        props: ['travelTip', 'showMap'],

        directives: {
            lazyload: LazyLoadDirective
        },

        components: {
            Hooper,
            Slide,
            HooperNavigation,
        },

        data() {
            return {
                imgUrl: CLEVERTRIP_IMG_URL,
                isMapModalVisible: false,
                noItemsError: false,
                itemWithoutLocationError: false,
                unexpectedError: false,
                isMapLoading: false,
                isRouteLoading: false,
                redrawMap: true,
                map: null,
                allCoords: [],
                allRoutes: [],
                allGeometries: [],
                allMarkers: [],
                allDaysMapyczUrls: [],
                showDayButtons: false,
                activeDay: -1,
                markerItemInfo: {
                    title: "",
                    description: "",
                    itineraryItemPictures: []
                },
                activeMarkerId : null,
                showMarkerInfo: false,
                hooperSettings: {
                    itemsToShow: 1,
                    infiniteScroll: false,
                    trimWhiteSpace: true
                },
                itemInfoReloadKey: 0,
            }
        },

        mounted() {
            /*eslint-disable*/
            Loader.async = true
            Loader.load(null, null);
            Loader.lang = "cs";
            /*eslint-enable*/
        },

        watch: {
            '$route' (to, from){
                if (to.name == "travelTipDetail") {
                    this.resetAllToDefault()
                }
            },
            showMap(nV) {
                if (nV == null) return 
                if (nV == true) {
                    this.showMapModal()
                }
                else {
                    this.hideMapModal()
                }
            }
        },

        methods: {
            resetAllToDefault() {
                this.showDayButtons = false
                this.activeDay = -1
                this.allRoutes = []
                this.allMarkers = []
                this.noItemsError = false
                this.itemWithoutLocationError = false
                this.unexpectedError = false
                this.redrawMap = true
                this.hideMapModal();
                const mapEl = document.getElementById("map")
                mapEl.replaceChildren();
            },
            hideMarkerInfo(syncPort = true) {
                if (this.activeMarkerId) {
                    const iconEl = document.getElementById(this.activeMarkerId)
                    this.activeMarkerId = null
                    iconEl.style.zIndex = ""
                    iconEl.childNodes[1].style.display = "none"
                    iconEl.childNodes[0].style.border = "2px solid #fff" 
                }
                this.showMarkerInfo = false
                if (syncPort) {
                    this.$nextTick(() => {
                        this.map.syncPort(true)
                    })
                }
            },
            highlightRoute(index) {
                if (!this.allGeometries[index]) return
                for (const [_, geo] of Object.entries(this.allGeometries[index])) {
                  geo.setOptions({color: "#3BEA7E", width: 6, outlineColor: "#222", outlineWidth: 2})

                  // Puts route above other routes
                  let nodes = geo.getNodes()
                  for (var i = 0; i < nodes.length; i++) {
                    const node = nodes[i]
                    const svgRouteElement = node.parentElement
                    node.parentElement.parentElement.appendChild(svgRouteElement)
                  }
                }
                this.highlightMarkers(index, true)
            },
            unhighlightRoute(index) {
                if (!this.allGeometries[index]) return
                for (const [key, geo] of Object.entries(this.allGeometries[index])) {
                    geo.setOptions({color: "#3BEA7E", width: 5, outlineColor: "#fff", outlineWidth: 1})
                }
                this.highlightMarkers(index, false)
            },
            showDay(index) {
                this.hideMarkerInfo()
                if (index == -1) {
                    this.allRoutes.forEach(layer => {layer.enable()})
                    this.allMarkers.forEach(layer => {layer.enable()})
                    this.activeDay = index
                    let centerZoom = this.map.computeCenterZoom(this.allCoords);
                    this.map.setCenterZoom(centerZoom[0], centerZoom[1], true);
                    return
                }
                this.highlightMarkers(index, false)
                this.unhighlightRoute(index)
                this.activeDay = index
                this.allRoutes.forEach(layer => {
                    layer.disable()})
                this.allMarkers.forEach(layer => {layer.disable()})
                this.allRoutes[index].enable()
                this.allMarkers[index].enable()

                let coordsInDay = []
                this.allMarkers[index].getMarkers().forEach(marker => {
                    coordsInDay.push(marker.getCoords())
                })
                let centerZoom = this.map.computeCenterZoom(coordsInDay);
                this.map.setCenterZoom(centerZoom[0], centerZoom[1], true);
            },

            highlightMarkers(dayIndex, highlight) {
                this.allMarkers[dayIndex].getMarkers().forEach(marker => {
                    const container = marker.getContainer()["3"]
                    if (container.id == this.activeMarkerId) return
                    container.style.zIndex = highlight ? "2" : ""
                    const markerIcon = marker.getContainer()["3"].childNodes[0]
                    markerIcon.style.border = `2px solid ${highlight ? "#222" : "#fff"}`
                })
            },

            handleRouteInteractions() {
                /*eslint-disable*/
                const _this = this
                this.map.getSignals().addListener(this, "map-redraw", function(e) {
                    if (_this.showDayButtons) {
                        _this.allRoutes.forEach(async (route, index) => {
                            if (typeof route.getGeometries != 'function') return
                            _this.allGeometries[index] = route.getGeometries()
                            for (const [key, geo] of Object.entries(_this.allGeometries[index])) {
                                let nodes = geo.getNodes()
                                for (var i=0;i<nodes.length;i++) {
                                var node = nodes[i];
                                // Show only the current day on route click
                                JAK.Events.addListener(node, "click", () => {
                                    _this.showDay(index)
                                    _this.highlightMarkers(index, false)
                                    
                                });
                                // Highlight dayRoute on mouseover
                                JAK.Events.addListener(node, "mouseover", () => {
                                    _this.highlightMarkers(index, true)
                                    _this.highlightRoute(index)
                                    _this.prevActiveDay = _this.activeDay
                                    _this.activeDay = index
                                });

                                JAK.Events.addListener(node, "mouseout", () => {
                                    _this.highlightMarkers(index, false)
                                    _this.activeDay = _this.prevActiveDay
                                    _this.unhighlightRoute(index)
                                });
                                }
                            }
                            
                
                        })
                    }
                });
                /*eslint-enable*/
            },

            createMarker(itineraryItem, isFirstItemTypeStart, index) {
                /*eslint-disable*/
                let _this = this
                let interestPointCoords = SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude);
                let markerElement = JAK.mel("div", {}, {cursor: "pointer"});
                markerElement.id = itineraryItem.id
                
                function highlightMarker(e, isVisible, element = null) {
                    if (!isVisible && _this.activeMarkerId == itineraryItem.id) return
                    const iconEl = element ? element : e.target.parentElement
                    iconEl.style.zIndex = isVisible ? 1 : ""
                    iconEl.childNodes[1].style.display = isVisible ? "block" : "none"
                    iconEl.childNodes[0].style.border = `2px solid ${isVisible ? "#222" : "#fff"}` 
                }

                markerElement.addEventListener("click", function(e) {
                    _this.markerItemInfo = {
                        title: itineraryItem.title,
                        description: itineraryItem.description,
                        itineraryItemPictures: itineraryItem.itineraryItemPictures
                    }

                    // If there is active marker and it isn't this one -> unhighlight it 
                    if (_this.activeMarkerId != null && _this.activeMarkerId != itineraryItem.id) {
                        const markerEl = document.getElementById(_this.activeMarkerId)
                        highlightMarker(e, false, markerEl)                        
                    }

                    _this.activeMarkerId = itineraryItem.id
                    highlightMarker(e, true)

                    // rerenderElement for image lazyload directive to work correctly 
                    _this.itemInfoReloadKey ++
                    setTimeout(() => {
                        let carousel = _this.$refs.carousel
                        if (carousel) {
                            carousel.update()
                            carousel.slideTo(0)
                        }
                    }, 1);
                    if (!_this.showMarkerInfo) {
                        _this.showMarkerInfo = true
                        setTimeout(() => {
                            _this.map.syncPort(true)
                        }, 300);
                    }
                    _this.map.setCenter(interestPointCoords, true)
                })
            
                let markerIcon = JAK.mel("div");
                let markerText
                if (itineraryItem.itineraryItemType == "START") markerIcon.classList.add("start-icon")
                else if (itineraryItem.itineraryItemType == "FINISH") markerIcon.classList.add("finish-icon")
                else {
                    markerIcon = JAK.mel("div", {}, {position: "absolute", top: "-15px", left: "-6px", width: "32px", height: "32px", border: "2px solid white", backgroundColor: "#3bea7e", borderRadius: "50%", filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)"});
                    markerText = JAK.mel("div", {}, { position:"absolute", cursor: 'default',  pointerEvents: "none", left:"-1px", top:"-11px", textAlign:"center", width:"22px", color:"#222"});
                    markerText.innerHTML = isFirstItemTypeStart ? index : index + 1;
                }

                let markerTitle = JAK.mel("div", {}, {position: "absolute", zIndex: "1", display: "none", top: "-13px", left: "32px", width: "max-content", lineHeight: "20px", padding: "4px 10px", height: "28px", backgroundColor: "#3BEA7E", filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)"});
                markerTitle.innerHTML = itineraryItem.title

                // Show item title on mouseover
                JAK.Events.addListener(markerIcon, "mouseover", (e) => highlightMarker(e, true))
                JAK.Events.addListener(markerIcon, "mouseout", (e) => highlightMarker(e, false))

                markerElement.appendChild(markerIcon);
                markerElement.appendChild(markerTitle)
                if (typeof(markerText) != "undefined") {
                    markerElement.appendChild(markerText);
                }
                return new SMap.Marker(interestPointCoords, null, {url: markerElement, anchor: { left: 10, bottom: 1 } })  
                /*eslint-enable*/
            },

            loadMap: async function () {
                try {
                    /*eslint-disable*/
                    if (!Array.isArray(this.travelTip.itineraryDays) || !this.travelTip.itineraryDays.length)  return this.noItemsError = true;
                    let itineraryDays = [...this.travelTip.itineraryDays].sort((a,b)=> a.dayNumber -b.dayNumber)
                    if (itineraryDays[0].itineraryItems.length < 1) return this.noItemsError = true
                    if (!itineraryDays[0].itineraryItems[0].location) return this.itemWithoutLocationError = true

                    let center = SMap.Coords.fromWGS84(itineraryDays[0].itineraryItems[0].location.longitude, itineraryDays[0].itineraryItems[0].location.latitude);
                    this.map = new SMap(JAK.gel('map'), center, 16);
                    this.map.setZoomRange(5, 18)
                    this.map.addDefaultLayer(SMap.DEF_BASE).enable();
                    this.map.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_WHEEL | SMap.MOUSE_ZOOM, {minDriftSpeed:1/0}));
                    this.map.addControl(new SMap.Control.Keyboard(SMap.KB_PAN | SMap.KB_ZOOM, {focusedOnly:false}));
                    this.map.addControl(new SMap.Control.Selection(2));
                    this.map.addControl(new SMap.Control.Scale(), {bottom:"32px",left:"8px"});
                    this.map.addControl(new SMap.Control.Zoom({}, { titles: ["Přiblížit", "Oddálit"], showZoomMenu: false }), {right: "47px", top: "10px"});
                    this.isMapLoading = false
                    this.isRouteLoading = true
                    this.redrawMap = false
                    this.allCoords = []

                    this.handleRouteInteractions()

                    let firstRouteCallInTheDay = true;
                    for (const [index, itineraryDay] of itineraryDays.entries()) {
                      let coords = [];
                      let interestPointMarkers = [];
                      let itemIdsWithCoords = [];
                      let itineraryItems = [...itineraryDay.itineraryItems]
                      itineraryItems.sort((a, b) => {
                            if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1
                            else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1
                            else return a.itemNumber - b.itemNumber;
                          })

                      let isFirstItemTypeStart = itineraryItems[0]?.itineraryItemType == "START"
                      let realIndexOfItem = -1
                      for (const [index, itineraryItem] of itineraryItems.entries()) {
                          if (!itineraryItem.location) return this.itemWithoutLocationError = true
                          if (itineraryItem.itineraryItemType !== 'EMPTY') realIndexOfItem += 1
                          const isPreviousItemInTheSameLocation = itineraryItems[index-1]?.location.latitude == itineraryItem.location.latitude && itineraryItems[index-1]?.location.longitude == itineraryItem.location.longitude
                          if (!isPreviousItemInTheSameLocation) {
                            itemIdsWithCoords.push({type: itineraryItem.itineraryItemType, id: itineraryItem.id, coords: SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude), transferTypes: itineraryItem.transferTypeValues});
                            coords.push(SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude));
                          }

                          if (itineraryItem.itineraryItemType !== 'EMPTY') {
                            const marker = this.createMarker(itineraryItem, isFirstItemTypeStart, realIndexOfItem)
                            interestPointMarkers.push(marker);
                          }
                      }

                      coords.forEach(coord => this.allCoords.push(coord))

                      this.allMarkers[index] = new SMap.Layer.Marker();
                      this.map.addLayer(this.allMarkers[index]).enable();

                      for (const interestPoint of interestPointMarkers) {
                          this.allMarkers[index].addMarker(interestPoint);
                      }

                      if (coords.length > 1) {
                        let finalCoordsToCall = []
                        for (let i = 0; i < itemIdsWithCoords.length - 1; i++) {
                            finalCoordsToCall.push(itemIdsWithCoords[i].coords);
                            // If next item is 'empty item' -> skip creating route. 
                            if (itemIdsWithCoords[i + 1].type == "EMPTY") continue
                            else finalCoordsToCall.push(itemIdsWithCoords[i + 1].coords);

                            let criterion = 'turist1'
                            criterion = this.getRouteCriterion(itemIdsWithCoords, i)
                            if (criterion == 'vector') {
                                this.createGeometry(finalCoordsToCall, index, firstRouteCallInTheDay)
                                finalCoordsToCall = []
                                firstRouteCallInTheDay = false;
                                continue
                            }

                            await SMap.Route.route(finalCoordsToCall, {
                                geometry: true,
                                criterion: criterion
                            }).then((route) => {
                              let routeCoords = route.getResults().geometry;
                              this.createGeometry(routeCoords ? routeCoords : finalCoordsToCall, index, firstRouteCallInTheDay)
                              firstRouteCallInTheDay = false;
                            })

                            finalCoordsToCall = []
                        }
                        let urlRoute = new SMap.URL.Route()
                        for (let i=0; i <itemIdsWithCoords.length; i++) {
                          if (itemIdsWithCoords[i].type == 'EMPTY') continue
                          urlRoute.addWaypoint(itemIdsWithCoords[i].coords, {type: 'trial'})

                        }
                        const finalUrl = urlRoute.toString()
                        this.allDaysMapyczUrls[index] = finalUrl

                        firstRouteCallInTheDay = true;
                      }
                      else if (coords.length == 0) this.allRoutes[index] = {_id: itineraryDay.id, enable: () => {}, disable: () => {}, disabled: true} 
                      else {
                      // Inserts one point in a day to list of all dayRoutes. This prevents breaking when highlighting each day (create empty enable & disable functions)
                      this.allRoutes[index] = {_id: itineraryDay.id, enable: () => {}, disable: () => {}}

                      let pointCoords = coords[0].toWGS84()
                      let singleItemUrl = `https://mapy.cz/turisticka?q=${pointCoords[1]}N%20${pointCoords[0]}E`
                      this.allDaysMapyczUrls[index] = singleItemUrl
                      }
                    }
                    
                    this.showDayButtons = true
                    let centerZoom = this.map.computeCenterZoom(this.allCoords);
                    this.map.setCenterZoom(centerZoom[0], centerZoom[1]);
                    this.isRouteLoading = false
                } catch (error) {
                    console.log(error)
                    this.unexpectedError = true            
                }
                /*eslint-enable*/
            },

            createGeometry(coords, index, createNewLayer) {
                /*eslint-disable*/
                if (createNewLayer) {
                  this.allRoutes[index] = new SMap.Layer.Geometry()
                  this.map.addLayer(this.allRoutes[index]).enable();
                }

                const options = {
                    color: "#3BEA7E",
                    minDist: 8,
                    opacity: 1,
                    outlineColor: "white",
                    outlineOpacity: 1,
                    outlineWidth: 1,
                    outlineStyle: JAK.Vector.STYLE_SOLID,
                    width: 5,
                    style: JAK.Vector.STYLE_SOLID
                }
                try {
                    let geometry = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, coords, options);
                    this.allRoutes[index].addGeometry(geometry)
                } catch (error) {
                    console.log(error)
                }
                /*eslint-enable*/
            },

            getRouteCriterion(items, index) {
                const nextItemHasOnlyOneTransferType = items[index + 1].transferTypes && items[index + 1].transferTypes.length == 1
                if (nextItemHasOnlyOneTransferType) {
                    const transferType = items[index + 1].transferTypes[0].transferType
                    if (transferType == 'WALK') return 'turist1'
                    else if (transferType == 'CAR') return 'fast'
                    else if (['BIKE', 'SCOOTER'].includes(transferType)) return 'bike1'
                    else if (transferType == 'ROLLER_SCATE') return 'bike2'
                    else if (['BUS', 'CABLE_CAR', 'FLIGHT', 'BOAT', 'PUBLIC_TRANSPORT', 'TRAIN'].includes(transferType)) return 'vector'
                }
                else {
                    const nextItemTransferTypes = items[index + 1].transferTypes.map(transfer => transfer.transferType)
                    if (nextItemTransferTypes.every(type => type == 'WALK')) return 'turist1'
                    else if (nextItemTransferTypes.every(type => type == 'CAR')) return 'fast'
                    else if (nextItemTransferTypes.every(type => ['BIKE', 'SCOOTER'].includes(type))) return 'bike1'
                    else if (nextItemTransferTypes.every(type => type == 'ROLLER_SCATE')) return 'bike2'
                    else if (nextItemTransferTypes.every(type => ['BUS', 'CABLE_CAR', 'FLIGHT', 'BOAT', 'PUBLIC_TRANSPORT', 'TRAIN'].includes(type))) return 'vector'
                    else return 'turist1'
                }
            },

            showMapModal: function () {
            this.isMapModalVisible = true;
            document.body.style.overflow = "hidden"

            if (this.redrawMap) {
                this.isMapLoading = true
                this.loadMap()
            }
            },
            hideMapModal: function () {
                if (this.map) this.map.removeCard()
                this.hideMarkerInfo(false)
                this.$emit("hideMapModal")
            },
        },

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.image-container {
  max-height: 230px;
  width: 40%;
  padding: 20px 20px 0 0;
  position: relative;

  figure {
    margin: 0;
    margin-inline: auto;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .carousel-container, .image-container {
    display: none;
  }
}

.carousel-container {
  width: 40%;
  padding-inline: 40px;
  margin-top: 20px;



  .hooper-prev, .hooper-next {
    width: 40px;
    height: 40px;
  }
}

.image-slide {
  display: flex;
  align-items: center;
  
  figure {
    margin: 0;
    padding-inline: 10px;
    margin-inline: auto;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.marker-item-info {
  width: 100%;
  max-height: 40%;
  height: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: $tr-white;
  position: relative;
  z-index: 2;

    &.show {
    height: 100%;
    transition: height 0.2s ease-in;
  }

  .item-info-container {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    gap: 20px;
    .item-title-with-description {
      width: 100%;
  
      .item-title {
        margin-bottom: 5px;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: pre-wrap;
      }
      .item-description {
        text-align: justify;
        max-width: 940px;
        overflow: auto;
        margin: 0;
        padding-right: 10px;
        height: calc(100% - 31px);
      }
    }
  
    .item-pictures {
      margin-top: 30px;
      flex-shrink: 0;
      white-space: nowrap;
      max-width: 350px;
      overflow-x: auto;
      overflow-y: hidden;

        .picture {
          height: 100%;
          display: inline-block;
          padding-inline: 5px;
          
          figure {
            height: 100%;
    
            img {
              aspect-ratio: 4/3;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
    }

    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
      gap: 0;

      .item-title {
        margin: 0
      }

      .item-title-with-description {
        height: 100%;
      }

      .item-pictures {
        display: none;
      }
    }
  }


  .dismiss-x {
    position: absolute;
    border: none;
    top: 5px;
    right: 5px;
    z-index: 1;

    &::before, &::after {
      background-color: $tr-black;
    }
  }

}

.travel-tip-detail-page-modal {
  position: relative;
  box-shadow: 2px 2px 20px 0px rgb(0 0 0 / 35%);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: 100%;

  @media screen and (max-width: 1100px) {
    width: 90%;
  }
  & > .x-background {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 10px;
    right: 10px;
    background-color: $tr-white;
    z-index: 2;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.3);

    & .dismiss-x::after {
      width: 3px;
    }
    & .dismiss-x::before {
      height: 3px;
    }

    &:hover {
      & .dismiss-x::after, & .dismiss-x::before {
        background-color: $tr-green !important;
      } 
    }

    .dismiss-x {
        position: absolute;
        border: none;
        top: 1px;
        right: 1px;
        z-index: 1;
    
        &::before, &::after {
          background-color: $tr-black;
        }
      }
  }
}



.travel-tip-detail-page-modal-body {
  position: relative;
  touch-action: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .day-pick-buttons {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 125px);
    height: 27px;
    gap: 10px;
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;

    button {
      height: 100%;
      padding-block: 0;
      box-shadow: 0 0 2px 0 rgba(0,0,0,.3) !important;
      background-color: $tr-white;
      color: $tr-black;
      border: none;

      &.disabled {
        background-color: #e1e1e1 !important;
        cursor: not-allowed;
        color: #bcbcbc !important; 
      }

      &.active {
        background-color: $tr-green !important;
        color: $tr-black !important;
      }

      &:hover {
        color: $tr-green;
      }
    }

    & > div {
      height: 100%;
      padding-block: 0;
      box-shadow: 0 0 2px 0 rgba(0,0,0,.3) !important;
      background-color: $tr-white;
      color: $tr-black;
      border: none;
      padding-inline: 7px;

      a {
        color: $tr-black;
        text-decoration: underline;
        vertical-align: middle;

        &:hover {
          color: #606060;
        }
      }
    }
  }

  & .map-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $tr-white;
    padding: 5px 10px;
    z-index: 5;
    width: max-content;
    max-width: 100%;
    text-align: center;
  }
}

.travel-tip-detail-page-modal-body-map {
  width: 100%;
  height: 100%;
  transition: height 0.2s;
  background-color: $tr-white;
}

.travel-tip-detail-page-modal-body-map {
width: 100%;
}

</style>

<style lang="scss">
    @import '@/scss/variables';

.smap .zoom .button-group {
  border-radius: 0 !important;
  button {
    color: $tr-black !important;

    &:hover {
      color: $tr-green !important;
      background-color: $tr-white !important;
    }
  }
}
.smap.public-map img[src*="//api.mapy.cz/v"]{
    filter: grayscale(100%)  brightness(1.1) contrast(1.05);
}
.smap .noprint a, .smap .noprint div {
    z-index: 50;
}
.smap.public-map.travel-tip-detail-page-modal-body-map .start-icon, .smap.public-map .finish-icon {
    position: absolute;
    top: -17px;
    left: -9px; 
    width: 38px;
    height: 38px;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    background-color: $tr-green;
    border-radius: 50%;
    border: 2px solid $tr-white;
    background-size: 28px;
    background-repeat: no-repeat;
    background-position: center;
}
.smap.travel-tip-detail-page-modal-body-map .start-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m16 22 13-5-13-5v10Zm0-12v24' stroke='%23222'/%3E%3Ccircle cx='25' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='22' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='19' cy='33' r='1' fill='%23222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") !important;}
.smap.travel-tip-detail-page-modal-body-map .finish-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M34 12v10H16V12h18Zm-18-2v24' stroke='%23222'/%3E%3Ccircle cx='13' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='10' cy='33' r='1' fill='%23222'/%3E%3Ccircle cx='7' cy='33' r='1' fill='%23222'/%3E%3Cpath fill='%23222' d='M16 12h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
.smap svg {
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.smap g {
  cursor: pointer;
}

.carousel-container {
  .hooper-prev, .hooper-next {
    width: 40px;
    height: 40px;
  }

  .hooper-prev {
    left: -40px !important;
  }

  .hooper-next {
    right: -40px !important;
  }
}
</style>
