<template>
    <div class="col-12 col-md-7 col-lg-7 offset-lg-1 ml-0 itinerary-item-details">
      <div class="item-body">
        <div class="mobile-item-header">
          <div class="d-flex align-items-center">
            <div class="icon" :class="{default: itineraryItem.itineraryItemType == 'DEFAULT', start: itineraryItem.itineraryItemType == 'START', finish: itineraryItem.itineraryItemType == 'FINISH'}"></div>
            <div class="item-number-with-title">
              <p>{{ itineraryItem.itineraryItemType == 'DEFAULT' ? $t("general.itemOrder", [itemNo]) : itineraryItem.itineraryItemType == 'START' ? $t("general.startItem") : $t("general.finishItem") }}</p>
              <h3>{{ itineraryItem.title }}</h3>
            </div>
          </div>
          <div class="difficulty-with-duration" :class="{'justify-content-around': ammountOfIcons == 2, 'justify-content-center': ammountOfIcons == 1}">
            <div v-if="totalLength > 0" v-tooltip.top="$t('info.totalRouteLengthToItem')">
              <div class="icon length"></div>
              <p>{{ totalLength }} km</p>
            </div>
            <div class="difficulty" v-if="itineraryItem.difficulty" v-tooltip.tip="$t('general.difficulty')">
              <div class="icon difficulty"></div>
              <p>{{ getDifficulty() }}</p>
            </div>
            <div v-if="itineraryItem.duration" class="duration" v-tooltip.top="$t('info.itemEstimatedTime')">
              <div class="icon duration"></div>
              <p>{{ getDuration() }}</p>
            </div>
          </div>
        </div>
        <h3 class="item-title">{{ itineraryItem.title }}</h3>
        <article class="itinerary-description">
          <p>{{itineraryItem.description }}</p>
          <div v-if="itineraryItem && itineraryItem.warning">
              <h6 style="font-weight: 600; margin-block: 30px 6px;">{{ $t("inputLabels.practicalInfo") }}:</h6>
              <p style="white-space: pre-line" v-html="warningWithUrls()"></p>
          </div>
        </article>
        <div class="picture-carousel-wrapper" v-if="itineraryItem.itineraryItemPictures.length > 0">
          <ImageCarousel :images='itineraryItem.itineraryItemPictures'/>
        </div>
      </div>
      <div v-if="nextItemTransfers" class="transfers-container">
          <div class="vertical-dashed-line"></div>
          <div class="trip-icons">
            <div class="transfer-with-length-container" v-for="transfer in transferTypesUsed" :key="transfer.id">
              <div class="vertical-dashed-line short"></div>
              <div class="transfer-with-length">
                <img v-tooltip.top="findTransfer(transfer.type).text" :src="getImgUrl(transfer.type)" :alt="transfer.type">
                <div v-if="transfer.length" class="length">{{ Math.round(transfer.length * 10) / 10 }} km</div>
              </div>
              <div class="vertical-dashed-line short"></div>
            </div>
          </div>
          <div class="vertical-dashed-line"></div>
        </div>
  </div>
</template>

<script>
    import ImageCarousel from "@/components/ImageCarousel";
    import sharedUtils from '@/utils/sharedUtils'

    export default {
        name: "ItineraryItem",

        components: {
          ImageCarousel,
        },

        props: {
            itineraryItem: [Object],
            perex: String,
            itemNo: Number,
            nextItemTransfers: Array
        },

        data() {
            return {
                transferTypesUsed: [],
                totalLength: null,
            }
        },

        created: function() {
          this.formatTransferIcons()
        },

        computed: {
          ammountOfIcons() {
            let ammount = 0
            if (this.itineraryItem.duration) ammount += 1
            if (this.itineraryItem.difficulty) ammount += 1
            if (this.totalLength > 0) ammount += 1
            return ammount
          },
          transferTypes() {
            return [
              {value: 'WALK', text: this.$t("general.walk"), icon: 'icon-walking.svg'},
              {value: 'PUBLIC_TRANSPORT', text: this.$t("general.publicTransport"), icon: 'icon-public-transport.svg'},
              {value: 'BUS', text: this.$t("general.bus"), icon: 'icon-bus.svg'}, 
              {value: 'TRAIN', text: this.$t("general.train"), icon: 'icon-train.svg'},
              {value: 'BIKE', text: this.$t("general.bike"), icon: 'icon-bike.svg'},
              {value: 'ROLLER_SCATE', text: this.$t("general.rollerScate"), icon: 'icon-rollerscate.svg'},
              {value: 'SCOOTER', text: this.$t("general.scooter"), icon: 'icon-scooter.svg'},
              {value: 'BOAT', text: this.$t("general.boat"), icon: 'icon-boat.svg'},
              {value: 'CABLE_CAR', text: this.$t("general.cableCar"), icon: 'icon-cablecar.svg'},
              {value: 'CAR', text: this.$t("general.car"), icon: 'icon-car.svg'},
              {value: 'FLIGHT', text: this.$t("general.flight"), icon: 'icon-plane.svg'}
            ]
          },
        },

        methods: {
          getImgUrl(type) {
            let icon = this.findTransfer(type).icon
            return require(`@/assets/svg/${icon}`);
          },
          findTransfer(type) {
            return this.transferTypes.find(transfer => transfer.value == type)
          },
          warningWithUrls() {
              //eslint-disable-next-line
              const URLMatcher = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
              if (!this.itineraryItem.warning) return
              let withLinks = this.itineraryItem.warning.replace(URLMatcher, match => `<a style="word-break: break-all; color: #17d560" target="_blank" href="${match}">${match}</a>`)

              return withLinks
          },
          formatTransferIcons() {
            let transfersUsed = [];
            let length = 0
            if (this.nextItemTransfers) {
              for(const [index, transferTypeValue] of this.nextItemTransfers.entries()) {
                if (!transfersUsed.some(transfer => transfer.transferType == transferTypeValue.transferType)) {
                  transfersUsed.push({id: index ,type: transferTypeValue.transferType, length: transferTypeValue.length})
                }
              }
            }

            this.itineraryItem?.transferTypeValues?.forEach(transfer => length += transfer.length)
            this.totalLength = Number.isInteger(length) ? length : length.toFixed(1)
            this.transferTypesUsed = transfersUsed;
          },

          getDuration() {
            if (!this.itineraryItem.duration) return
            const hours = Math.floor(this.itineraryItem.duration / 60);
            const minutes = this.itineraryItem.duration % 60;

            return `${hours > 0 ? hours + ' h' : ''} ${minutes > 0 ? minutes + ' m': ''}`;
          },

          getDifficulty() {
            return sharedUtils.getDifficultyTextFromValue(this.itineraryItem.difficulty)
          },
        }

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

  @media screen and (min-width: 768px) {
    .itinerary-item-details {
      border-bottom: 1px solid $tr-light-gray;
    }
  }
  

    @media screen and (max-width: 768px) {
      .itinerary-description, .picture-carousel-wrapper {
        max-width: 569px;
        margin-inline: auto;
      }
    }

    .mobile-item-header, .transfers-container {
      max-width: 570px;
      margin-inline: auto;
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .transfers-container {
      .vertical-dashed-line {
        border-left: $tr-light-gray 2px dashed;
        margin-left: 12px;
        height: 100%;
  
        &.short {
          height: 20px;
          margin-top: 2px;
        }
      }
      .trip-icons {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 2px;
        margin-left: 10px;
  
        .transfer-with-length {
          display: flex;
          align-items: center;
          margin-block: 5px;
  
          .length {
            margin-left: 20px;
            color: $tr-gray;
          }
        }
      }

    }

    .mobile-item-header {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 3px dashed $tr-light-gray;

      .icon {
        min-width: 40px;
        height: 40px;
        background-size: 40px;
        background-repeat: no-repeat;
        
        &.default {
          background-image: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M17 26v8H7v-8h2v1.5h2V26h2v1.5h2V26h2Zm15.5-14.518c0 .494-.233 1.184-.648 1.99-.406.79-.953 1.631-1.508 2.406a37.509 37.509 0 0 1-1.844 2.357 38.818 38.818 0 0 1-1.844-2.357c-.555-.775-1.102-1.615-1.508-2.405-.415-.807-.648-1.497-.648-1.99a3.991 3.991 0 0 1 4-3.983c2.211 0 4 1.785 4 3.982Z" stroke="%23222"/><circle cx="29" cy="21" r="1" fill="%23222"/><circle cx="29" cy="24" r="1" fill="%23222"/><circle cx="29" cy="30" r="1" fill="%23222"/><circle cx="29" cy="33" r="1" fill="%23222"/><circle cx="21" cy="12" r="1" fill="%23222"/><circle cx="26" cy="33" r="1" fill="%23222"/><circle cx="18" cy="12" r="1" fill="%23222"/><circle cx="29" cy="27" r="1" fill="%23222"/><circle cx="23" cy="33" r="1" fill="%23222"/><circle cx="15" cy="12" r="1" fill="%23222"/><circle cx="20" cy="33" r="1" fill="%23222"/><path d="M7.5 13.5v-2.566l2-1.333 2 1.333V13.5h-4Z" stroke="%23222"/><circle cx="28.5" cy="11.5" r="1.5" stroke="%23222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h40v40H0z"/></clipPath></defs></svg>');
        }
        &.start {
          background-image: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="m16 22 13-5-13-5v10Zm0-12v24" stroke="%23222"/><circle cx="25" cy="33" r="1" fill="%23222"/><circle cx="22" cy="33" r="1" fill="%23222"/><circle cx="19" cy="33" r="1" fill="%23222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h40v40H0z"/></clipPath></defs></svg>');
        }
        &.finish {
          background-image: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M34 12v10H16V12h18Zm-18-2v24" stroke="%23222"/><circle cx="13" cy="33" r="1" fill="%23222"/><circle cx="10" cy="33" r="1" fill="%23222"/><circle cx="7" cy="33" r="1" fill="%23222"/><path fill="%23222" d="M16 12h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm6-8h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3zm3 2h3v2h-3zm-3 2h3v2h-3z"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h40v40H0z"/></clipPath></defs></svg>');
        }
        
        &.duration, &.difficulty, &.length {
          min-width: 24px;
          height: 24px;
          background-size: 24px;
        }

        &.length {
          background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><circle cx="13" cy="17" r="1" fill="%23222"/><circle cx="10" cy="19" r="1" fill="%23222"/><circle cx="7" cy="21" r="1" fill="%23222"/><path d="M21.5 6c0 .29-.14.72-.413 1.251a13.041 13.041 0 0 1-.993 1.578A24.535 24.535 0 0 1 19 10.233a24.535 24.535 0 0 1-1.094-1.404 13.041 13.041 0 0 1-.993-1.578C16.64 6.72 16.5 6.29 16.5 6a2.5 2.5 0 0 1 5 0Zm-13 8c0 .29-.14.72-.413 1.251a13.04 13.04 0 0 1-.993 1.578A24.5 24.5 0 0 1 6 18.233a24.5 24.5 0 0 1-1.094-1.404 13.04 13.04 0 0 1-.993-1.578C3.64 14.72 3.5 14.29 3.5 14a2.5 2.5 0 0 1 5 0Z" stroke="%23222"/><circle cx="19" cy="6" r="1" fill="%23222"/><circle cx="16" cy="15" r="1" fill="%23222"/><circle cx="19" cy="13" r="1" fill="%23222"/><circle cx="6" cy="14" r="1" fill="%23222"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
        }

        &.difficulty {
          background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M2 11a.5.5 0 0 0 0 1v-1Zm0 1h1v-1H2v1Zm20 0a.5.5 0 0 0 0-1v1Zm-1 0h1v-1h-1v1Z" fill="%23000"/><path stroke="%23000" d="M7.5 11.5h9"/><path d="M4.25 9v5m15.5-5v5" stroke="%23000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path stroke="%23000" stroke-width="1.5" stroke-linecap="round" d="M6.25 8.25v6.5m11.5-6.5v6.5"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>')
        }

        &.duration {
          background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)" stroke="%23222"><path d="M12.5 8v5l2.5 2.5"/><circle cx="12.5" cy="12.5" r="7"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
        }
      }

      .item-number-with-title {
        margin-left: 20px;

        p {
          color: $tr-gray;
        }

        p, h3 {
          margin: 0;
          line-height: 20px;
        }
      }

      .difficulty-with-duration {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        p {
          margin-bottom: 0;
          font-size: 12px;
          color: $tr-gray;
        }
      }
    }

    .item-body {
      text-align: left;
      margin-block: 32px 30px;

      @media (max-width: 768px) {
        padding-top: 30px;
        border-top: 1px solid $tr-light-gray;
        border-bottom: 1px solid $tr-light-gray;
        margin-block: 0 !important;

        h3.item-title {
          display: none;
        }
      }

      h3 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .itinerary-description {
      white-space: pre-wrap;
    }

</style>
