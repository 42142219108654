<template>
    <li class="equipment-container">
        <div class="equipment" :class="{active: isEditable, 'visible-background': isEditable}">
            <button v-if="isEditable" class="img-button remove-equipment-button" @click="deletePersonalEquipment"></button>
            <div v-if="!isPreviewEquipment" class="hint" :data-hint="$t('public.travelTipDetailPage.settled')">
                <Checkbox v-model="equipment.settled" :bg-white="isEditable"/>
            </div>
            <div v-if="!isPreviewEquipment" class="hint" :data-hint="$t('public.travelTipDetailPage.packed')">
                <Checkbox v-model="equipment.packed" :bg-white="isEditable" activeBackgroundColor="#FF8515"/>
            </div>
            <p v-if="isPreviewEquipment || !isEditable" class="quantity">{{ equipment.quantity }}x</p>
            <input v-else id="quantity" type="number" @input="onInputChange" v-model="equipment.quantity" min="1" max="20" placeholder="0x">
            <a class="title" :class="{'font-weight-bold': equipment.importance === 'MANDATORY'}" v-if="!isEditable && equipment.productLink" :href="equipment.productLink" target="_blank">
               {{ equipment.title }}
            </a>
            <p v-else-if="!isEditable" class="title" :class="{'font-weight-bold': equipment.importance === 'MANDATORY'}">{{ equipment.title }}</p>
            <input ref="title-input" v-else :disabled="!isEditable" id="title" type="text" v-model="equipment.title" @input="onInputChange" placeholder="Nová položka" maxlength="50">
            <a v-if="equipment.productLink" :href="equipment.productLink" target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5864_15560)">
                    <path d="M13.2779 11.6589C13.1797 11.4414 13.0412 11.2376 12.8625 11.0589C12.0693 10.2657 10.7832 10.2657 9.98992 11.0589L8.26637 12.7825C7.47313 13.5757 7.47313 14.8618 8.26637 15.655C9.05961 16.4483 10.3457 16.4483 11.1389 15.655L12.1444 14.6496" stroke="#222"/>
                    <path d="M10.7723 12.2857C10.8656 12.4645 10.9873 12.6322 11.1375 12.7824C11.9307 13.5756 13.2168 13.5756 14.01 12.7824L15.7336 11.0588C16.5268 10.2656 16.5268 8.97947 15.7336 8.18623C14.9404 7.39299 13.6543 7.39299 12.861 8.18623L11.8556 9.19163" stroke="#222"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_5864_15560">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </a>
            
        </div>
    </li>

</template>

<script>
import Checkbox from '@/components/Checkbox'
import EquipmentService from '../services/EquipmentService';

    export default {
        props: {
            equipment: {
                type: Object,
                required: true
            },
            isEditable: {
                type: Boolean,
                default: false
            },
            isTripEquipment: {
                type: Boolean,
                default: false
            },
            isPreviewEquipment: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                debounceTimeout: null,
            }
        },

        components: {
            Checkbox
        },

        watch: {
            'equipment.settled'(nV) {
                if (!nV && this.equipment.packed) {
                    this.equipment.packed = false
                }
                if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout);
                }
                this.debounceTimeout = setTimeout(() => {
                    this.updatePersonalEquipment()
                }, 500);
            },
            'equipment.packed'(nV) {
                if (nV && !this.equipment.settled) {
                    this.equipment.settled = true
                }
                if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout);
                }
                this.debounceTimeout = setTimeout(() => {
                    this.updatePersonalEquipment()
                }, 500);
            }
        },

        methods: {
            onInputChange() {
                if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout);
                }
                this.debounceTimeout = setTimeout(() => {
                    this.updatePersonalEquipment()
                }, 1000);
            },
            updatePersonalEquipment() {
                if (this.isTripEquipment) {
                    this.$emit("copyTravelTipEquipments")
                    return
                }
                EquipmentService.updatePersonalEquipment(this.equipment)
                .then(resp => {
                    console.log(resp)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            deletePersonalEquipment() {
                this.$emit('deletePersonalEquipment', this.equipment)
            }
        },
    }

</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .equipment-container {
        display: flex;
        align-items: center;

        .remove-equipment-button {
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
            background-size: 15px;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
        }
        .equipment {
            width: 100%;
            padding: 5px 10px;
            display: flex;
            gap: 10px;
            align-items: center;

            
            &.visible-background, &.visible-background input {
                background-color: $tr-light-gray;
            }

            .hint {
                position: relative;

                &::before {
                    display: none;
                    position: absolute;
                    top: -23px;
                    color: $tr-gray;
                    content: attr(data-hint);
                    left: 50%;
                    transform: translateX(-50%);
                }

                &:hover::before {
                    display: block;
                }
            }
            
            .quantity {
                margin: 0;
            }

            .title {
                width: 100%;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 5px;
                word-break: break-word;
                color: $tr-black;
            }

            a.title:hover {
                color: $tr-gray;
            }
            
            input {
                border: none;
                padding-left: 5px;

                &:disabled {
                    background-color: transparent;
                }
            }

            #title {
                width: 100%;
            }
            
            &.active input{
                background-color: $tr-white;
            }

            .confirm-button {
                width: 24px;
                height: 24px;
                background-size: 24px;
                background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
                background-repeat: no-repeat;
                background-position: center;
                flex-shrink: 0;
            }
        }
    }


</style>