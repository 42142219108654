<template>
    <div class="star-rating">
        <label
            class="star-rating__star"
            v-for="(rating, index) in 5"
            :class="{'is-disabled': disabled}"
            @click="set(index + 1)"
            @mouseover="star_over(index + 1)"
            @mouseout="star_out"
            :key="index"
        >
        <input
            class="star-rating__checkbox"
            type="radio"
            :value="index + 1"
            :name="name"
            v-model="internalValue"
            :disabled="disabled"
        />
        <img v-if="getStarType(index + 1) === 'full'" :src="fullStar" alt="Full Star" />
        <img v-else-if="getStarType(index + 1) === 'quarter'" :src="quarterStar" alt="Quarter Star" />
        <img v-else-if="getStarType(index + 1) === 'half'" :src="halfStar" alt="Half Star" />
        <img v-else-if="getStarType(index + 1) === 'threeQuarter'" :src="threeQuarterStar" alt="Three quarter Star" />
        <img v-else :src="emptyStar" alt="Empty Star" />
        </label>
    </div>
</template>
  
<script>
    export default {
        props: {
            name: String,
            value: null,
            disabled: Boolean,
        },
    
        data() {
            return {
                temp_value: null,
                internalValue: this.value,
                emptyStar: require("@/assets/svg/icon-star-empty.svg"),
                fullStar: require("@/assets/svg/icon-star-full.svg"),
                halfStar: require("@/assets/svg/icon-star-half.svg"),
                quarterStar: require("@/assets/svg/icon-star-quarter.svg"),
                threeQuarterStar: require("@/assets/svg/icon-star-three-quarter.svg"),
            };
        },
    
        watch: {
            value(newValue) {
                this.internalValue = newValue;
            },
        },
    
        methods: {
            star_over(index) {
                if (!this.disabled) {
                    this.temp_value = this.internalValue;
                    this.internalValue = index;
                }
            },
        
            star_out() {
                if (!this.disabled) {
                    this.internalValue = this.temp_value;
                }
            },
        
            set(value) {
                if (!this.disabled) {
                    this.temp_value = value;
                    this.internalValue = value;
                    this.$emit('input', value);
                }
            },
            getStarType(rating) {
                if (rating <= this.internalValue) {
                    return 'full';
                } else if (rating <= this.internalValue + 0.25) {
                    return 'threeQuarter';
                } else if (rating <= this.internalValue + 0.5) {
                    return 'half';
                } else if (rating <= this.internalValue + 0.75) {
                    return 'quarter';
                } else {
                    return 'empty';
                }
            },
        },
    };
</script>
  
<style scoped lang="scss">
    .star-rating {
        display: flex;
        height: 30px;

        &__star {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            font-size: 1.5em;
            transition: color .2s ease-out;
            margin-bottom: 0;

            &:hover {
                cursor: pointer;
            }
            
            &.is-disabled:hover {
                cursor: default;
            }
        }

        &__checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px; width: 1px;
            margin: -1px; padding: 0; border: 0;
        }
    }
</style>