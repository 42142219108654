export const DEFAULT_CATEGORIES = [
    {
        attribute: 'overallInterest',
        nameCzech: 'Celkový dojem',
        nameEnglish: 'Overall interest',
    },
    {
        attribute: 'priceQualityRatio',
        nameCzech: 'Poměr cena / kvalita',
        nameEnglish: 'Price quality ratio',
    },
    {
        attribute: 'photogenic',
        nameCzech: 'Fotogeničnost',
        nameEnglish: 'Photogenic',
    },
    {
        attribute: 'correspondsToTheDescription',
        nameCzech: 'Odpovídá popisu',
        nameEnglish: 'Corresponds to the description',
    },
]

export const FOOD_CATEGORIES = [
    {
        attribute: "trafficAvailability",
        nameCzech: "Dostupnost dopravy",
        nameEnglish: "Traffic availability"
    },
    {
        attribute: "cleanliness",
        nameCzech: "Čistota",
        nameEnglish: "Cleanliness"
    },
    {
        attribute: "personnel",
        nameCzech: "Personál",
        nameEnglish: "Personnel"
    },
    {
        attribute: "atmosphere",
        nameCzech: "Atmosféra",
        nameEnglish: "Atmosphere"
    }
]


export const TOURISM_CATEGORIES = [
    {
        attribute: 'trafficAvailability',
        nameCzech: 'Dostupnost dopravy',
        nameEnglish: 'Traffic availability'
    },
    {
        attribute: 'availabilityOfPublicFacilities',
        nameCzech: 'Dostupnost veřejných zařízení',
        nameEnglish: 'Availability of public facilities'
    },
    {
        attribute: 'accommodation',
        nameCzech: 'Ubytování',
        nameEnglish: 'Accommodation'
    },
    {
        attribute: 'difficultyMatching',
        nameCzech: 'Náročnost odpovídá popisu',
        nameEnglish: 'Difficulty matching'
    },
];

export const SPORT_CATEGORIES = [
    {
        attribute: 'availabilityOfEquipment',
        nameCzech: 'Dostupnost vybavení',
        nameEnglish: 'Availability of equipment'
    },
    {
        attribute: 'uniqueness',
        nameCzech: 'Unikátnost',
        nameEnglish: 'Uniqueness'
    },
];

export const CULTURE_CATEGORIES = [
    {
        attribute: 'trafficAvailability',
        nameCzech: 'Dostupnost dopravy',
        nameEnglish: 'Traffic availability'
    },
    {
        attribute: 'sights',
        nameCzech: 'Pamětihodnosti',
        nameEnglish: 'Sights'
    }
];