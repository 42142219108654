import gql from 'graphql-tag'

export const TRAVEL_TIP_ASSESSMENTS = gql`query travelTipAssessments($travelTipId:Int!) {
  assessments: travelTipAssessments(travelTipId: $travelTipId){
      id
      createdAt
      comment
      author {
        id
        userName
        avatarFileName
      }
      overallRating
      overallInterest
      priceQualityRatio
      priceValueRatio
      locality
      cleanliness
      personnel
      atmosphere
      trafficAvailability
      availabilityOfPublicFacilities
      availabilityOfEquipment
      accommodation
      difficultyMatching
      uniqueness
      sights
      correspondsToTheDescription
      photogenic
  }
}`;

export const AVERAGE_RATINGS_FOR_CATEGORIES = gql`query averageRatingsForAllCategories($travelTipId:ID!) {
  averageRatings: averageRatingsForAllCategories(travelTipId: $travelTipId){
      categoryName,
      meanRating
  }
}`;