<template>
  <div>
  <div id="itinerary-list" :class="itineraryListClass">
    <div v-for="(itineraryDay, index) in sortedItineraryDays" :key='itineraryDay.id' class="row itinerary-item-row" :id="'day-row'+index" :class="{'firstDay': index == 0}">
      <ItineraryDayMenu :itineraryDay='itineraryDay' :dayNo="index+1"/>
      <ItineraryDay :itineraryDay='itineraryDay'/>
    <b-collapse class="w-100" :id="'day-colapse' + itineraryDay.id" :visible="false">
    <div class="w-100 day-itinerary-items">
      <div class="itinerary-items d-flex col-12 text-left p-0" v-for="(itineraryItem, index) in sortedItineraryItems(itineraryDay.itineraryItems)" :key="itineraryItem.id">
          <ItineraryItemMenu :itineraryItem='itineraryItem' :nextItemTransfers="nextItemTransfers(itineraryDay.itineraryItems, index)" :itemNo="getItemNumber(itineraryDay, index)" :index="index"/>
          <ItineraryItem :itineraryItem='itineraryItem' :nextItemTransfers="nextItemTransfers(itineraryDay.itineraryItems, index)" :itemNo="getItemNumber(itineraryDay, index)"/>
      </div>
    </div>
    </b-collapse>
    </div>
  </div>
  <div class="nav-days-fixed" v-if="sortedItineraryDays.length > 1">
    <div class="day-buttons-container">
      <div class="day-buttons">
        <button :id="'day-el' + index" class="day-bar-button" @click="scrollToDay(index)" :class="{active: dayinViewIndex == index}" v-for="(day, index) in itineraryDays" :key="day.id">{{ $t("general.dayOrder", [index + 1]) }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import ItineraryDay from "../components/ItineraryDay";
import ItineraryDayMenu from "@/components/ItineraryDayMenu";
import ItineraryItemMenu from "@/components/ItineraryItemMenu";
import ItineraryItem from "@/components/ItineraryItem";

export default {
  name: "ItineraryDayList",

  props: {
    itineraryDays: {
      type: Array
    },
    viewportWidth: {
      type: Number
    }
  },

  data() {
    return {
      dayElements: [],
      dayinViewIndex: null,
    }
  },

  components: {
    ItineraryDay,
    ItineraryDayMenu,
    ItineraryItemMenu,
    ItineraryItem
  },
  
  watch: {
    itineraryDays(nV) {
      this.setElements()
    },
  },

  mounted: function () {
    this.setElements()
    window.addEventListener('scroll', this.showDaysProgress)
  },
  destroyed: function() {
    window.removeEventListener('scroll', this.showDaysProgress)
  },

  methods: {
    nextItemTransfers(items, index) {
      return this.sortedItineraryItems(items)[index + 1]?.transferTypeValues
    },
    getItemNumber(itineraryDay, index) {
      return this.sortedItineraryItems(itineraryDay.itineraryItems)[0].itineraryItemType == 'START' ? index : index + 1
    },
    isScrollingOver() {
      clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          this.isScrolling = false
          document.removeEventListener('scroll', this.isScrollingOver)
        }, 100);
    },
    scrollToDay(index) {
      const dayTitleEl = document.getElementById('day-row' + index)
      dayTitleEl.scrollIntoView({behavior: "smooth"})

      this.scrollTimeout = null
      this.isScrolling = true
      document.addEventListener('scroll', this.isScrollingOver)
    },
    setElements() {
      if (!this.itineraryDays || !document.getElementById('day-row' + (this.itineraryDays.length - 1))) return setTimeout(() => {this.setElements()}, 50);
      this.dayElements = []
      this.$nextTick(() => {
        for (let i = 0; i < this.itineraryDays.length; i++) {
          let el = document.getElementById('day-row' + i)
          this.dayElements[i] = el
        }
      })
    },
    showDaysProgress() {
      if (this.throttleTimer) return;
      this.throttleTimer = true;

      setTimeout(() => {
        if (!this.itineraryDays || this.itineraryDays.length < 2 || !this.dayElements[0]) return this.throttleTimer = false
        let daysOffsets = []
        for (let i = 0; i < this.itineraryDays.length; i++) {
          daysOffsets.push(this.dayElements[i].offsetTop)
        }
        const element1 = document.querySelector(".firstDay");
        if (!element1) return this.throttleTimer = false

        //which dayEl is the has closest LOWER offsetTop to window page offsetTop (in which day is the user located) - highlights the correct day button
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let closestLower = daysOffsets.reduce(function(prev, curr) {
          return (curr <= scrollTop + 50 && curr >= prev) ? curr : prev;
        }, -Infinity);
        
        this.dayinViewIndex = daysOffsets.indexOf(closestLower)
        let dayButtonsEl = document.querySelector(".day-buttons-container")

        const isScrollable = dayButtonsEl.scrollWidth > dayButtonsEl.clientWidth  
        if (isScrollable) {
          // If no day is active scroll to start
          if (closestLower == -Infinity && !this.isScrolling) dayButtonsEl.scrollTo({top: 0, left: 0, behavior: "smooth"})
  
          // If atleast part of active button is offscreen -> scroll to make it more visible
          else if (!this.isScrolling) {
            let activeButton = document.querySelector("#day-el" + this.dayinViewIndex)
            const rect = activeButton.getBoundingClientRect()
            const viewWidth = window.innerWidth || document.documentElement.clientWidth
            
  
            if ((rect.left <= 0 || rect.right >= viewWidth)) {
              let newScrollPosition = dayButtonsEl.scrollLeft
              if (rect.right >= viewWidth) newScrollPosition = newScrollPosition + (viewWidth / 2)
              if (rect.left <= 0) newScrollPosition = newScrollPosition - (viewWidth / 2)
  
              dayButtonsEl.scrollTo({top: 0, left: newScrollPosition, behavior: "smooth"})
            }
          }
        }
        
        this.throttleTimer = false
    }, 100);
    },
    sortedItineraryItems(items) {
      if (!items) return
      let itineraryItems = items.filter(item => item.itineraryItemType != 'EMPTY')
      return itineraryItems.sort((a, b) => {
        if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1
        else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1
        else return a.itemNumber - b.itemNumber
      })
    },

  },

  computed: {
    itineraryListClass() {
      if (!this.itineraryDays) return
      if (this.itineraryDays.length == 1) return 'one-day'
      if (this.itineraryDays.length == 2) return 'two-days'
      if (this.itineraryDays.length > 2) return 'over-three-days'
      return ''
    },
    sortedItineraryDays() {
      if (typeof this.itineraryDays === "function" || this.itineraryDays === undefined || !this.itineraryDays)
        return [];
      const sortedItineraryDays = [...this.itineraryDays];
      return sortedItineraryDays.sort((a, b) => {
        if (a.dayNumber < b.dayNumber) {
          return -1;
        }
        if (a.dayNumber > b.dayNumber) {
          return 1;
        }
        return 0;
      })
    }
  },
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

#itinerary-list {
  padding-bottom: 80px;
}

.nav-days-fixed {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $tr-white;
  transition: 0.25s ease-in-out;
  transition-property: right, visibility;
  filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 10px);
  // -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   display: none;


  .day-buttons-container {
    width: 100%;
    overflow-x: auto;

    .day-buttons {
      width: max-content;
      margin: auto;
      .day-bar-button {
        background-color: transparent;
        z-index: 2;
        border: none;
        padding: 5px 10px;
        height: 30px;
        margin-inline: 5px;
        line-height: 20px;


      @media (min-width: 768px) {
        &:hover {
          color: $tr-green;
        }
      }
        &.active {
          background-color: $tr-green !important;
          color: $tr-black;
        }
      }
    }
  }


  @media screen and (max-width: 768px) {
    display: block;
    height: 30px;

    // h5 {
    //   display: none;
    // }
    .day-bar-button {
      margin-inline: 0 !important;
    }
  }
}

</style>
