<template>
    <div>
        <div class="assessments-title">
            <h3>{{ $t("general.assessments") }}</h3>
            <span>{{"(" + (assessments && assessments.length) + ")" }}</span>
        </div>
        <div class="assessment-overview">
            <div class="overall-rating">
                <div v-if="$apollo.loading" >{{ $t("loading.loading") }}</div>
                <div v-else-if="assessments.length < 1">
                    <h5>{{ $t("public.assessments.beFirstToReview") }}</h5>
                </div>
                <div v-else class="d-flex flex-column">
                    <h4 class="overall-rating-title">{{ $t("public.assessments.overallRating") }}</h4>
                    <StarRating name="rating" v-model="averageOverallRating" :disabled="true" />
                </div>
                <TrekioButton v-if="isUserLoggedIn && hasUserReviewed === false" @click="showModal" primary>{{ $t("buttons.addAssessment") }}</TrekioButton>
                <div v-else-if="!isUserLoggedIn">
                    <p>{{ $t("public.assessments.loginToAddAssessment") }}</p>
                    <TrekioButton @click="redirectToLogin" primary>{{ $t("buttons.login") }}</TrekioButton>
                </div>
                
            </div>
            <div v-if="!$apollo.loading && assessments.length > 0 && averageRatings && averageRatings.length > 0" class="subcategories">
                <div class="rating-category-container" v-for="category in averageRatings" :key="category.categoryName">
                    <div class="title">{{ $t("public.assessments." + category.attribute) }}</div>
                    <StarRating name="rating" v-model="category.meanRating" :disabled="true"/>
                </div>
            </div>
        </div>
        <div class="assessments-background" v-if="!$apollo.loading">
            <div class="assessments-container">
                <TravelTipAssessment v-for="(assessment, index) in visibleAssessments" :key="assessment.id" 
                :assessment="assessment" :index="index" :assessmentsLength="visibleAssessments.length" :windowResized="windowResized"
                @refetchAssessments="refetchAssessments" @showEditAssessmentModal="showEditAssessmentModal"
                />
            </div>
            <div v-if="assessments && assessments.length > visibleAssessments.length" class="more-assessments" @click="expandAssessments">
                <img src="@/assets/svg/icon-arrow-down-bold.svg" alt="arrow">
            </div>
        </div>
        <b-modal class="modal pl-0 white-bg" id="rate-travel-tip-modal" white-bg hide-footer hide-header>
            <form @submit.prevent="onSubmit">
                <h4>{{ $t("public.assessments.rateTravelTip") }}</h4>
                <div class="overall-rating-container">
                    <div class="title"> {{ $t("public.assessments.overallRating") }} *</div>
                    <div :class="{'scale-animation': showAnimation}" @animationend="showAnimation = false">
                        <StarRating name="rating" v-model="form.overallRating" :disabled="false"/>
                    </div>
                </div>
                <div v-if="form.overallRating != 0" class="subcategories">
                    <div class="rating-category-container" v-for="(category, index) in form.categories" :key="category.attribute">
                        <div class="title">{{ $t("public.assessments." + category.attribute) }}</div>
                        <StarRating name="rating" v-model="form.categories[index].value" :disabled="false"/>
                    </div>
                </div>
                <TrekioTextarea id="comment" :label="$t('public.assessments.comment')" trim v-model="form.comment" maxlength="1024"></TrekioTextarea>
                <div class="buttons-container">
                    <TrekioButton type="button" @click="$bvModal.hide('rate-travel-tip-modal')" secondary>{{$t("general.back")}}</TrekioButton>
                    <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" primary>{{ $t("buttons.rate") }}</TrekioButton>
                </div>
            </form>
        </b-modal>
    </div>
</template>
  
<script>
import {TRAVEL_TIP_ASSESSMENTS, AVERAGE_RATINGS_FOR_CATEGORIES} from "@/api/graphql/query/TravelTipAssessmentQuery";
import TravelTipAssessmentService from '@/services/TravelTipAssessmentService'
import { DEFAULT_CATEGORIES, FOOD_CATEGORIES, TOURISM_CATEGORIES, CULTURE_CATEGORIES, SPORT_CATEGORIES } from '@/utils/assessmentCategories.js'
import StarRating from '@/components/StarRating'
import TravelTipAssessment from "./TravelTipAssessment.vue";

    export default {
        name: 'TravelTipAssessments',
        props: ["travelTipId", 'theme', 'windowResized'],

        components: {
            StarRating,
            TravelTipAssessment
        },

        data() {
            return {
                displayedAssessments: 3,
                displayedAssessmentsStep: 3,
                assessments: [],
                hasUserReviewed: null,
                currentUsersAssessmentId: null,
                form: {
                    comment: '',
                    travelTip: this.travelTipId,
                    overallRating: 0,
                    categories: [

                    ]
                },
                averageOverallRating: 0,
                loading: '',
                showAnimation: false,
            }
        },

        computed: {
            isUserLoggedIn() {
                return this.$store.state.currentUserId != null
            },
            isUserAdminOrSupervisior() {
                return ['ADMIN', 'ADMIN_SUPERVISOR'].includes(this.$store.state.currentUserRole)
            },
            visibleAssessments() {
                if (!this.assessments) return []
                return this.assessments.slice().reverse().sort((a, b) => {
                    if (a.author.id == this.$store.state.currentUserId) {
                        return -1;
                    } else if (b.author.id == this.$store.state.currentUserId) {
                        return 1;
                    }

                    return new Date(b.createdAt) - new Date(a.createdAt);
                }).slice(0, this.displayedAssessments);
            },
        },

        methods: {
            showEditAssessmentModal(assessment) {
                let assessmentCategories = {...assessment}
                
                this.currentUsersAssessmentId = assessment.id
                this.form.comment = assessment.comment
                this.form.overallRating = assessment.overallRating

                delete assessmentCategories.id;
                delete assessmentCategories.author;
                delete assessmentCategories.comment;
                delete assessmentCategories.createdAt;
                delete assessmentCategories.overallRating;

                Object.entries(assessmentCategories).forEach(([key, value]) => {
                    const category = this.form.categories.find(cat => cat.attribute === key);
                    if (category) {
                        category.value = value;
                    }
                });

                this.$bvModal.show('rate-travel-tip-modal')
            },
            redirectToLogin() {
                this.$router.push({path: "/login", query: {returnUrl: this.$router.currentRoute.path + "#assessments"}})
            },
            showModal() {
                this.$bvModal.show('rate-travel-tip-modal')
            },
            validateInputs() {
                if ([1, 2, 3, 4, 5].includes(this.form.overallRating)) {
                    return true
                } else {
                    this.showAnimation = true
                    return false
                }             
            },
            refetchAssessments() {
                this.$apollo.queries.assessments.refetch()
                this.$apollo.queries.averageRatings.refetch()
            },
            async onSubmit() {
                if (!this.validateInputs()) return
                this.loading = this.$t("loading.assessmentCreate")
                if (!this.hasUserReviewed) {
                    await TravelTipAssessmentService.createTravelTipAssessment(this.form)
                        .then(() => {
                            this.refetchAssessments()
                            this.$bvModal.hide('rate-travel-tip-modal')
                            this.resetToDefault()
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    await TravelTipAssessmentService.updateTravelTipAssessment(this.currentUsersAssessmentId, this.form)
                        .then(() => {
                            this.refetchAssessments()
                            this.$bvModal.hide('rate-travel-tip-modal')
                            this.resetToDefault()
                        })
                        .catch((err) => {
                            console.log(err)
                        })       
                }

                this.loading = ''
            },

            resetToDefault() {
                this.form.comment = ''
                this.form.overallRating = 0
                this.form.categories.forEach(category => {
                    category.value = 0
                })
            },

            expandAssessments() {
                this.displayedAssessmentsStep += 1
                this.displayedAssessments += this.displayedAssessmentsStep
            },

            setupCategories(travelTipCategories) {
                this.form.categories = []
                DEFAULT_CATEGORIES.forEach(category => {
                    this.form.categories.push({...category, value: 0})
                })
                travelTipCategories.forEach(category => {
                    this.form.categories.push({...category, value: 0})
                })
            },

            pickCorrectCategories(categoryName) {
                switch (categoryName) {
                    case "FOOD":
                        this.setupCategories(FOOD_CATEGORIES)
                        break;
                    case "TOURISM":
                        this.setupCategories(TOURISM_CATEGORIES)
                        break;
                    case "SPORT":
                        this.setupCategories(SPORT_CATEGORIES)
                        break;
                    case "CULTURE":
                        this.setupCategories(CULTURE_CATEGORIES)
                        break;
                
                    default:
                        break;
                }
            },

            setHasUserReviewed() {
                for (let i = 0; i < this.assessments.length; i++) {
                    if (this.assessments[i].author.id == this.$store.state.currentUserId) {
                        return true;
                    }
                }
                return false;
            },
            roundRating(rating) {
                // returns value rounded to .25
                return Math.round(rating * 4) / 4
            }
        },

        watch: {
            travelTipId(nV) {
                this.form.travelTip = nV
                this.$apollo.queries.assessments.start({
                    travelTipId: nV
                })
                this.displayedAssessments = 3
                this.displayedAssessmentsStep = 3
                this.resetToDefault()
            },
            theme(nV) {
                this.pickCorrectCategories(nV)
            },
        },

        created() {
            this.$apollo.queries.assessments.refetch()

            if (this.theme) {
                this.pickCorrectCategories(this.theme)
            }
            if (this.isUserLoggedIn) {
                this.hasUserReviewed = this.setHasUserReviewed()
            }
        },

        apollo: {
            assessments: {
                query: TRAVEL_TIP_ASSESSMENTS,
                variables: function() {
                    return {
                        travelTipId: this.travelTipId
                    }
                },
                skip: function() {
                    return !this.travelTipId
                },
                manual: true,
                result({data}) {
                    this.assessments = data.assessments
                    if (this.isUserLoggedIn) {
                        this.hasUserReviewed = this.setHasUserReviewed()
                    }

                },
            },
            averageRatings: {
                query: AVERAGE_RATINGS_FOR_CATEGORIES,
                variables: function() {
                    return {
                        travelTipId: this.travelTipId
                    }
                },
                skip: function() {
                    return !this.theme
                },
                update: function(data) {
                    if (data.averageRatings.length === 0) {
                        return this.$emit('setOverallRating', null)
                    }

                    // Filter out overallRating and leave only subcategories with value
                    return data.averageRatings.filter(rating => {
                        if (rating.meanRating === null) {
                            return false
                        } else if (rating.categoryName === 'overallRating') {
                            const roundedOverallRating = this.roundRating(rating.meanRating)
                            this.averageOverallRating = roundedOverallRating 
                            this.$emit('setOverallRating', roundedOverallRating)
                            return false
                        } else {
                            return true
                        }
                    }).map(rating => {
                        return {...rating, meanRating: this.roundRating(rating.meanRating), attribute: rating.categoryName}
                    })
                },
            }
        }
    };


</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .assessments-title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        h3, span {
            font-size: 25px;
            margin: 0;
        }

        span {
            margin-left: 6px;
            color: $tr-gray;
        }
    }

    .scale-animation {
        animation: scale 0.4s;
        transform-origin: left;
    }

    @keyframes scale {
        0% {
            transform:scale(1);
        }
        50% {
            transform: scale(1.4);
        }
        100% {
            transform: scale(1);
        }
    }
    .assessment-overview, .assessments-background {
        padding-inline: 50px;

        @media (max-width: 576px) {
            padding-inline: 20px;
        }
    } 
    .assessments-background {
        background-color: $tr-white;
        filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 10px);
    }

    .assessment-overview {
        padding-block: 30px;
        background-color: $tr-white;
        filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 10px);
        margin-bottom: 30px;

        .overall-rating {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 30px;

            .star-rating {
                scale: 1.3;
                transform-origin: left;
            }
        }

        .login {
            flex: 1;
            max-width: 330px;
            flex-basis: 280px;
        }

        .subcategories {
            margin-top: 20px;
            border-top: 1px solid $tr-light-gray;
        }
    }
    .subcategories {
        display: flex;
        row-gap: 20px;
        column-gap: 40px;
        flex-wrap: wrap;
        padding-top: 20px;
    }

    
    .overall-rating-container {
        display: flex;
        gap: 20px;
        align-items: center;

        @media (max-width: 768px) {
            justify-content: space-between;
            width: 100%;
        }
    }

    .rating-category-container {
        @media (max-width: 768px) {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    
    form {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-inline: 30px;
        padding-block: 20px;

        h4 {
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid $tr-light-gray;
        }


        .overall-rating-container {
            padding-block: 20px;
            border-bottom: 1px solid $tr-light-gray;
        }

        .subcategories {
            border-bottom: 1px solid $tr-light-gray;
            padding-bottom: 20px;
        }
        
        .buttons-container {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
        }

        .textarea-wrapper {
            margin-block: 20px;
        }
        
        @media (max-width: 576px) {
            padding-inline: 0;
        }
    }

    .more-assessments {
        height: 80px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $tr-light-gray;
    }

</style>
  