import gql from "graphql-tag";

export const CREATE_TRAVEL_TIP_ASSESSMENT = gql`
    mutation createTravelTipAssessment(
        $comment: String, 
        $travelTip: ID!, 
        $overallRating: Int!, 
        $overallInterest: Int, 
        $priceQualityRatio: Int, 
        $priceValueRatio: Int, 
        $locality: Int, 
        $cleanliness: Int, 
        $personnel: Int, 
        $atmosphere: Int,
        $trafficAvailability: Int, 
        $availabilityOfPublicFacilities: Int, 
        $availabilityOfEquipment: Int, 
        $accommodation: Int, 
        $difficultyMatching: Int, 
        $uniqueness: Int, 
        $sights: Int,
        $correspondsToTheDescription: Int, 
        $photogenic: Int
    ) {
        createTravelTipAssessment(
            comment: $comment, 
            travelTip: $travelTip, 
            overallRating: $overallRating, 
            overallInterest: $overallInterest,
            priceQualityRatio: $priceQualityRatio, 
            priceValueRatio: $priceValueRatio, 
            locality: $locality, 
            cleanliness: $cleanliness, 
            personnel: $personnel, 
            atmosphere: $atmosphere,
            trafficAvailability: $trafficAvailability, 
            availabilityOfPublicFacilities: $availabilityOfPublicFacilities, 
            availabilityOfEquipment: $availabilityOfEquipment, 
            accommodation: $accommodation, 
            difficultyMatching: $difficultyMatching, 
            uniqueness: $uniqueness, 
            sights: $sights,
            correspondsToTheDescription: $correspondsToTheDescription, 
            photogenic: $photogenic
        ) {
            id
        }
    }
`;

export const UPDATE_TRAVEL_TIP_ASSESSMENT = gql`
    mutation updateTravelTipAssessment(
        $id: Int!,
        $comment: String, 
        $travelTip: ID!, 
        $overallRating: Int!, 
        $overallInterest: Int, 
        $priceQualityRatio: Int, 
        $priceValueRatio: Int, 
        $locality: Int, 
        $cleanliness: Int, 
        $personnel: Int, 
        $atmosphere: Int,
        $trafficAvailability: Int, 
        $availabilityOfPublicFacilities: Int, 
        $availabilityOfEquipment: Int, 
        $accommodation: Int, 
        $difficultyMatching: Int, 
        $uniqueness: Int, 
        $sights: Int,
        $correspondsToTheDescription: Int, 
        $photogenic: Int
    ) {
        updateTravelTipAssessment(
            id: $id,
            comment: $comment, 
            travelTip: $travelTip, 
            overallRating: $overallRating, 
            overallInterest: $overallInterest,
            priceQualityRatio: $priceQualityRatio, 
            priceValueRatio: $priceValueRatio, 
            locality: $locality, 
            cleanliness: $cleanliness, 
            personnel: $personnel, 
            atmosphere: $atmosphere,
            trafficAvailability: $trafficAvailability, 
            availabilityOfPublicFacilities: $availabilityOfPublicFacilities, 
            availabilityOfEquipment: $availabilityOfEquipment, 
            accommodation: $accommodation, 
            difficultyMatching: $difficultyMatching, 
            uniqueness: $uniqueness, 
            sights: $sights,
            correspondsToTheDescription: $correspondsToTheDescription, 
            photogenic: $photogenic
        ) {
            id
        }
    }
`;

export const DELETE_TRAVEL_TIP_ASSESSMENT = gql`
    mutation deleteTravelTipAssessment($id: Int!) {
        deleteTravelTipAssessment(id: $id)
    }
`;