<template>
    <section class="gallery-container">
        <div class="img-container" v-for="(img, index) in pictures" :key="img.id" >
            <img @click="showModal(index)" :src="imgUrl + img.fileName.slice(0,img.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + img.fileName.slice(img.fileName.lastIndexOf('.'))" alt="">
        </div>
        <div v-if="openModal" class="modal-overlay" @click="hideModal()">
            <div class="modal-pictures" @click.stop>
                <hooper ref="carouselModal" style="height: calc(100vh - 100px); width: 100%" :initialSlide="indexOfPicture" :wheel-control="false" :mouseDrag="false" :touchDrag="false" :transition="500" :autoPlay="false" :playSpeed="8000" :itemsToShow="1" :centerMode="false" pagination="no" :infiniteScroll="false" :perPage=2 :speed=300 :loop="true" :minSwipeDistance=10 :scrollPerPage="false" :paginationEnabled="false">
                    <slide v-for="image in pictures" :key="image.id" style="width: 100% !important; display: flex; align-items: center;">
                    <figure v-lazyload class="modal-img-container">
                        <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                        <img class="img-fluid" :alt="image.title" :data-url="imgUrl + image.fileName.slice(0,image.fileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + image.fileName.slice(image.fileName.lastIndexOf('.'))">
                        <div class="img-title">{{ image.title }}</div>
                    </figure>
                    </slide>
                    <hooper-navigation slot="hooper-addons" v-if="pictures && pictures.length > 1" @click.stop></hooper-navigation>
                </hooper>
                <div class="close-icon" @click="hideModal()"></div>
            </div>
        </div>
    </section>
</template>

<script>
import LazyLoadDirective from "@/components/LazyLoadDirective.js";
import { CLEVERTRIP_IMG_URL } from '../definitions';
import {Hooper, Slide, Navigation as HooperNavigation} from "hooper";

    export default {
        directives: {
            lazyload: LazyLoadDirective
        },
        components: {
            Hooper,
            Slide,
            HooperNavigation
        },

        props: {
            pictures: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                imgUrl: CLEVERTRIP_IMG_URL,
                openModal: false,
            }
        },
        methods: {
            showModal(index){
                this.indexOfPicture = index
                this.openModal = true
                document.querySelector('body').style.overflow = 'hidden'
            },
            hideModal(){
                this.openModal = false
                document.querySelector('body').style.overflow = 'auto'
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .gallery-container {
        columns: 300px;
        gap: 30px;

        .img-container {
            width: 100%;
            margin-bottom: 30px;

            img {
                cursor: pointer;
                max-width: 100%;
            }
        }
    }

    .modal-overlay {
        position: fixed;
        z-index: 1040;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background-color: #000000da;
    }

    .modal-pictures {
        position: relative;
        height: calc(100vh - 100px);
        width: calc(100vw - 200px);
        padding: 60px 0;
        border-radius: 20px;

        .modal-img-container {
            position: relative;
            margin-inline: auto;
            height: 100%;
            padding-top: 25px;
            margin-bottom: 0;

            img {
            object-fit: contain;
            width: 100%;
            height: 100%
            }
            .img-title {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -2px;
                width: 100%;
                color: $tr-white;
                padding-inline: 10px;
                word-break: break-word;
                text-align: center;
            }
        }

        @media screen and (max-width: 768px) {
            width: calc(100vw - 110px);
        }
    }

    .close-icon {
        cursor: pointer;
        position: absolute;
        width: 70px;
        height: 70px;
        top: 30px;
        right: -85px;
        z-index: 2000;
        background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='25' cy='25' r='25' fill='%2329414D'/%3E%3Cg clip-path='url(%23clip0_1677_3468)'%3E%3Cpath d='M17.8579 32.1421L32 18' stroke='white'/%3E%3Cpath d='M32.1421 32.1421L18 18' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1677_3468'%3E%3Crect width='24' height='24' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 70px;
    }

</style>