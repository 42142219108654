<template>
  <div class="carousel">
    <hooper ref="carousel" style="height: 100%;" :settings="hooperSettings" :wheel-control="false" :transition="500" :autoPlay="false" :playSpeed="8000" :itemsToShow="2" :centerMode="false" pagination="no" :infiniteScroll="false" :perPage=2 :speed=300 :loop="true" :minSwipeDistance=10 :scrollPerPage="false" :paginationEnabled="false">
      <slide class="image-slide" v-for="(image, index) in images" :key="image.id">
        <div class="trip-card">
          <div class="inner-wrap">
            <figure v-lazyload class="position-relative ratio-four-by-three">
              <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
              <img class="img-fluid" :alt="image.title" :data-url="imgUrl + image.fileName.slice(0,image.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + image.fileName.slice(image.fileName.lastIndexOf('.'))" @click="showModal(index)">
            </figure>
            <div class="title-wrapper">
              <p :data-title="image.title">{{image.title}}</p>
            </div>
          </div>
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons" v-if="images && images.length > getNumberOfItems"></hooper-navigation>
    </hooper>
    <div v-if="openModal" class="modal-overlay" @click="hideModal()">
      <div class="modal-pictures" @click.stop>
        <hooper ref="carouselModal" style="height: calc(100vh - 100px); width: 100%" :initialSlide="indexOfPicture" :wheel-control="false" :mouseDrag="false" :touchDrag="false" :transition="500" :autoPlay="false" :playSpeed="8000" :itemsToShow="1" :centerMode="false" pagination="no" :infiniteScroll="false" :perPage=2 :speed=300 :loop="true" :minSwipeDistance=10 :scrollPerPage="false" :paginationEnabled="false">
            <slide v-for="image in images" :key="image.id" style="width: 100% !important; display: flex; align-items: center;">
              <figure v-lazyload class="modal-img-container">
                <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                <img class="img-fluid" :alt="image.title" :data-url="imgUrl + image.fileName.slice(0,image.fileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + image.fileName.slice(image.fileName.lastIndexOf('.'))">
                <div class="img-title">{{ image.title }}</div>
              </figure>
            </slide>
            <hooper-navigation slot="hooper-addons" v-if="images && images.length > 1" @click.stop></hooper-navigation>
        </hooper>
        <div class="close-icon" @click="hideModal()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyLoadDirective from "@/components/LazyLoadDirective.js";
import {CLEVERTRIP_IMG_URL} from "@/definitions";
import {Hooper, Slide, Navigation as HooperNavigation} from "hooper";
import 'hooper/dist/hooper.css';

export default {
  name: "ImageCarousel",

  directives: {
    lazyload: LazyLoadDirective
  },

  props: {
    images: null,
    showAllItemsTitle: {
      type: Boolean,
      default: true
    },
  },

  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },

  data() {
    return {
      openModal: false,
      indexOfPicture: null,
      width: window.innerWidth,
      hooperSettings: {
        itemsToShow: 1,
        breakpoints: {
          1200: {
            itemsToShow: 2
          },
        },
        infiniteScroll: false,
        trimWhiteSpace: true
      },
      imgUrl: CLEVERTRIP_IMG_URL,
      imgIsLoading: true,
    }
  },

  computed: {
    getNumberOfItems() {
      if (this.width >= 1200) {return 2}
      else {return 1}
    }
  },
  mounted() {
    //Temporary fix for not being able to slide through carousel after first load
    //Emitted from components/ItineraryDay
    this.$root.$on("update-carousel", this.updateCarousel)
    window.addEventListener('resize', this.getWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.getWidth);
  },

  methods: {
    updateCarousel(carousel = 'carousel') {
      setTimeout(() => {
        if (this.$refs[carousel]) {
          this.$refs[carousel].update()
        }
      }, 1)
    },
    getWidth() {
      this.width = window.innerWidth;
    },
    showModal(index){
      this.indexOfPicture = index
      this.openModal = true
      document.querySelector('body').style.overflow = 'hidden'
    },
    hideModal(){
      this.openModal = false
      document.querySelector('body').style.overflow = 'auto'
    },
  },
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.ratio-four-by-three {
  aspect-ratio: 4/3;
}

.modal-overlay {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal-pictures {
  position: relative;
  height: calc(100vh - 100px);
  width: calc(100vw - 200px);
  padding: 60px 0;
  border-radius: 20px;

  .modal-img-container {
    position: relative;
    margin-inline: auto;
    height: 100%;
    padding-top: 25px;
    margin-bottom: 0;
    
    img {
      object-fit: contain;
      width: 100%;
      height: 100%
    }
    .img-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -2px;
      width: 100%;
      color: $tr-white;
      padding-inline: 10px;
      word-break: break-word;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 110px);
  }
}
.close-icon {
  cursor: pointer;
  position: absolute;
  width: 70px;
  height: 70px;
  top: 30px;
  right: -85px;
  z-index: 2000;
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='25' cy='25' r='25' fill='%23222222'/%3E%3Cg clip-path='url(%23clip0_1677_3468)'%3E%3Cpath d='M17.8579 32.1421L32 18' stroke='white'/%3E%3Cpath d='M32.1421 32.1421L18 18' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1677_3468'%3E%3Crect width='24' height='24' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 70px;
}


.hooper-slide {
    width: 50% !important;

  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }
}


.trip-card {
  padding: 20px;
  .inner-wrap{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    background-color: $tr-white;
    padding: 20px 20px 13px 20px;

    img{
      object-fit: cover;
      cursor: pointer;
      width: 100%;
      height: 100%;
      margin-bottom: 15px;
    }

    .title-wrapper {
      height: 52px;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
        color: $tr-gray;
        position: relative;

        &::after {
          display: none;
          position: absolute;
          left: -20px;
          top: 0;
          background-color: $tr-white;
          content: attr(data-title);
          width: calc(100% + 40px);
          padding-inline: 20px;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          z-index: 2;
        }

        &:hover {
        overflow: visible;
        
        &::after {
            display: block;
          }
        }
      }
    }
  }
}

.carousel {
  width: 100%;
  padding: 30px 40px 10px;
}

@media screen and (max-width: 768px) {
  .close-icon {
    width: 55px;
    height: 55px;
    background-size: 55px;
    right: -55px;
  }
}

</style>

<style lang="scss">
    @import '@/scss/variables';

.modal-pictures {

  button.hooper-next, button.hooper-prev {
    width: 60px;
    height: 80px;
    background-position: center;
  }

  button.hooper-next {
    right: -80px;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='64' viewBox='0 0 35 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 62L32 32L2.00001 2' stroke='white' stroke-width='3'/%3E%3C/svg%3E%0A");
  }
  button.hooper-prev {
    left: -80px;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='64' viewBox='0 0 35 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33 62L3 32L33 2' stroke='white' stroke-width='3'/%3E%3C/svg%3E%0A");
  }
  @media screen and (max-width: 768px) {
    button.hooper-next, button.hooper-prev {
      width: 30px;
      height: 45px
    }
    button.hooper-next {right: -45px !important}
    button.hooper-prev {left: -45px !important}
  }
}

.picture-carousel-wrapper {
  max-width: 100vw; 

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 50px) !important;
  }
}

.image-slide {
  width: 50%;

  @media screen and (max-width: 1200px) {
    width: 100%;

    .trip-card {
      max-width: 430px;
      margin-inline: auto;
    }
  }
}
.hooper-next {
  right: -55px
}
.hooper-prev {
  left: -55px
}

.hooper-indicator {
  margin: 0 10px;
  width: 50px;
  height: 5px;
  border-radius: 0px;
  border-top: 2px solid $tr-white;
  border-bottom: 2px solid $tr-white;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  padding: 0;
  background-color: $tr-white;
  cursor: pointer;
}

@media (max-width: 767px) {
  .hooper-indicator {
    width: 7px;
  }
}

.hooper-indicator {
  background-color: $tr-gray;
}

.hooper-indicator.is-active {
  background-color: $tr-green;
  height: 7px;
}

</style>
